/* 底部tab css */
.tabbar {
  position: fixed;
  bottom: 0;
  z-index: 2;
  width: var(--max-width);
  display: flex;
  flex-flow: column;
  box-sizing: border-box;
  a {
    text-decoration: none;
  }
  &.slideUp {
    transition: transform 0.3s ease-in-out;
    transform: translateY(0);
  }
  &.slideDown {
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
  }
  /* 未登录 */
  .nologin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background: rgba(0, 47, 167, 0.8);
    .left {
      font-family: var(--font-family);
      font-size: 24px;
      color: #fff;
      font-weight: 400;
    }
    .rgBtn {
      width: 120px;
      height: 40px;
      background-color: #ffe600;
      font-family: var(--font-family);
      border: 0;
      font-size: 24px;
      color: var(--black);
      text-align: center;
      font-weight: 400;
      line-height: 40px;
    }
  }
}

/* 底部 tab */
.tabArea {
  display: flex;
  justify-content: space-between;
  height: var(--tabbar-hight);
  padding: 14px 62px;
  background-color: #fff;
}
.tabDot {
  display: flex;
  flex-direction: column;
}
.icon,
.home,
.home_active,
.buyCar,
.buyCar_active,
.collect,
.collect_active,
.sellCar,
.sellCar_active,
.mine,
.mine_active {
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  background-size: cover;
}

.home {
  background-image: url("./images/home.png");
}
.home_active {
  background-image: url("./images/home_active.png");
}
.buyCar {
  background-image: url("./images/buyCar.png");
}
.buyCar_active {
  background-image: url("./images/buyCar_active.png");
}
.collect {
  background-image: url("./images/collect.png");
}
.collect_active {
  background-image: url("./images/collect_active.png");
}
.sellCar {
  background-image: url("./images/sellCar.png");
}
.sellCar_active {
  background-image: url("./images/sellCar_active.png");
}

.mine {
  background-image: url("./images/mine.png");
}
.mine_active {
  background-image: url("./images/mine_active.png");
}

.title,
.title_active {
  font-family: PingFangSC-Regular;
  font-size: 24px;
  color: #93a3b8;
  text-align: right;
  font-weight: 400;
  margin-top: 6px;
}
.title_active {
  font-family: PingFangSC-Medium;
  color: #002fa7;
  font-weight: 500;
}
